import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import replayDatafeedState from "../../../../../../state/replay/atoms/replay_datafeed_state";
import { backtestingManagerState } from "../../../../../../state/backtesting/backtesting_manager_state";
import { lastBarState } from "../../../../../../state/replay/atoms/lastBar";
import { currentSessionState } from "../../../../../../state/backtesting/atoms/current_session";
import { ordersState } from "../../../../../../state/backtesting/atoms/orders";
import { symbolsState } from "../../../../../../state/backtesting/atoms/symbols";
import useSize from "../../hooks/useSize";
import { useFormik } from "formik";
import BacktestingNotification from "../BacktestingNotifications";
import { Qty, StopLoss, TakeProfit, Trade } from "../../../../../../state/backtesting/models/backtesting_models";
import BalanceDisplay from "./components/BalanceDisplay";
import { sessionsState } from "../../../../../../state/backtesting/atoms/sessions";
import CurrentTradesSL from "./components/CurrentTradesSL";
import CurrentTradesTP from "./components/CurrentTradesTP";
import { tradesState } from "../../../../../../state/backtesting/atoms/trades";
import { sessionOrdersSelector } from "../../../../../../state/backtesting/selectors/orders_selectors";

export default function OrderMaker() {
    const replayDatafeed = useRecoilValue(replayDatafeedState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const lastBar = useRecoilValue(lastBarState);
    const [currentSession, setCurrentSession] = useRecoilState(currentSessionState);
    const setOrders = useSetRecoilState(ordersState);
    const orders = useRecoilValue(sessionOrdersSelector(currentSession?.id ?? ""))
    const trades = useRecoilValue(tradesState)
    const symbols = useRecoilValue(symbolsState);
    const [showNotification, setShowNotification] = useState(false);
    const setSessions = useSetRecoilState(sessionsState);
    const [currentTrade, setCurrentTrade] = useState<Trade | undefined>(undefined);
    const [isOpenOrder, setIsOpenOrder] = useState(true);

    const [innerHeight, innerWidth] = useSize();

    useEffect(() => {
        if (currentSession != undefined) {
            const trade = trades.get(currentSession?.id ?? "")?.find((trade) => trade.status == "open");
            setCurrentTrade(trade);
        }
    }, [trades])

    const validate = (values: {
        side?: string,
        amount?: number,
        price?: number,
        orderType?: string,
        takeProfit?: number,
        stopLoss?: number,
        api?: string
    }) => {
        const errors: {
            side?: string,
            amount?: string,
            price?: string,
            orderType?: string,
            takeProfit?: string,
            stopLoss?: string,
            api?: string
        } = {};

        console.log("called validate")
        if (!values.side) {
            errors.side = "you have to buy or sell"
        }

        if (!values.amount) {
            errors.amount = "you have to select an amount"
        }

        if (!values.price && values.orderType == "limit") {
            errors.price = "you have to select a price for a limit order"
        }

        if (!values.orderType) {
            errors.orderType = "you have to select an order type"
        }

        if (values.side == "buy_long") {
            if (values.orderType == "limit") {
                if (values.amount! * values.price! > computeAvailableEquity(currentSession!.accountBalance)) {
                    errors.api = "this long exceed available equity"
                }
            } else {
                if (values.amount! * lastBar!.close > computeAvailableEquity(currentSession!.accountBalance)) {
                    errors.api = "this long exceed available equity"
                }
            }
        }

        if (values.side == "sell_long") {
            if (values.amount! > currentSession!.assetAmount) {
                errors.api = "this sell exceed asset balance"
            }
        }

        if (values.side == "sell_short") {
            if (values.orderType == "limit") {
                if (values.amount! * values.price! > computeAvailableEquity(currentSession!.accountBalance)) {
                    errors.api = "this short exceed available equity"
                }
            } else {
                if (values.amount! * lastBar!.close > computeAvailableEquity(currentSession!.accountBalance)) {
                    errors.api = "this short exceed available equity"
                }
            }
        }

        if (values.side == "buy_short") {
            if (values.amount! > currentSession!.amountOwed) {
                errors.api = "this buy exceed this position shorted amount"
            }
        }

        return errors;
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
    } = useFormik({
        initialValues: {
            orderType: "limit",
            amount: undefined,
            price: undefined,
            takeProfit: undefined,
            stopLoss: undefined
        },
        validate,
        onSubmit: async (values, helpers) => {
            try {
                // take order
                console.log("called submit")

                await backtestingManager.takeOrder(
                    setOrders,
                    currentSession!.id,
                    values.side!,
                    values.orderType!,
                    values.price!,
                    values.amount!,
                    lastBar!.time / 1000,
                    currentSession!.pairs[0],
                    [],
                    lastBar?.close,
                    values.stopLoss != undefined
                        ? new StopLoss(values.stopLoss, new Qty(values.amount, undefined), Math.round(Date.now() / 1000), lastBar!.time / 1000, false)
                        : undefined,
                    values.takeProfit != undefined
                        ? new TakeProfit(values.takeProfit, new Qty(values.amount, undefined), Math.round(Date.now() / 1000), lastBar!.time / 1000, false)
                        : undefined,
                    currentSession!.strategyId
                )

                if (values.orderType == "market") {
                    const session = await backtestingManager.getSession(setSessions, currentSession!.id);
                    setCurrentSession(session);
                }



                setTimeout(() => {
                    setShowNotification(true);
                }, 200)
            } catch (error) {
                errors.api = "failed to create order"
                return false;
            }

            return true;
        },
    });

    useEffect(() => {
        if (values.orderType == "market") {
            values.price = lastBar?.close;
        }
    }, [lastBar, orders]);

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const computeAvailableEquity = (accountBalance: number): number => {
        const pendingOrders = orders.filter((order) => order.state == "awaiting");

        let availabeEquity = accountBalance;

        for (let i = 0; i < pendingOrders.length; i++) {
            const openOrder = pendingOrders[i];

            if (openOrder.side == "buy_long" || openOrder.side == "sell_short") {
                availabeEquity -= openOrder.amount * openOrder.price;
            }
        }

        availabeEquity -= currentSession!.amountOwed * lastBar!.close

        return Math.round(availabeEquity * 1000) / 1000;
    }

    return (
        <>
            <BacktestingNotification
                show={showNotification}
                setShow={setShowNotification}
                title='Order Successfully Created'
                body={`Successfully ${values.side?.includes("buy") ? "Bought" : "Sold"} ${values.amount} ${symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? ""} for ${values.price} ${symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""}.`}
            />

            <div className='flex flex-col justify-start items-stretch space-y-6 lg:basis-1/5 md:h-1/5 flex-1 px-6 pt-12' style={{ height: innerHeight }}>
                <div>
                    <div className="mx-auto max-w-7xl">
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            <select
                                id="tabs"
                                name="tabs"
                                defaultValue={isOpenOrder ? "open" : "close"}
                                className="block w-full rounded-md border-none bg-white/5 py-2 pl-3 pr-10 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
                            >

                                <option key="open">Open</option>
                                <option key="close">Close</option>

                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="flex border-b justify-stretch border-white/10 py-4">
                                <ul
                                    role="list"
                                    className="flex min-w-full gap-x-6 px-2 text-sm font-semibold leading-6 text-gray-400"
                                >

                                    <li className="flex-1 leading-4 text-center" key="open">
                                        <button className={isOpenOrder ? 'text-indigo-400' : ''} onClick={() => setIsOpenOrder(true)}>
                                            open
                                        </button>
                                    </li>
                                    <li className="flex-1 leading-4 text-center" key="close">
                                        <button className={!isOpenOrder ? 'text-indigo-400' : ''} onClick={() => setIsOpenOrder(false)}>
                                            close
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='flex flex-col justify-start items-stretch space-y-6'>
                        <div>
                            <label htmlFor="amount" className="block text-sm font-medium leading-6 text-gray-50">
                                Position Size
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                                <input
                                    id="amount"
                                    name="amount"
                                    type="number"
                                    step="any"
                                    value={values.amount}
                                    placeholder="0"
                                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    {symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? ""}
                                </div>
                            </div>
                            {
                                errors.amount
                                    ? <div>
                                        <p className="text-red-500">{errors.amount}</p>
                                    </div>
                                    : null
                            }
                        </div>
                        <div>
                            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-50">
                                Market Price
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                                <input
                                    id="price"
                                    name="price"
                                    readOnly={values.orderType == "market"}
                                    value={values.orderType == "market" ? (lastBar?.close ?? 0) : values.price}
                                    type="number"
                                    step="any"
                                    placeholder={lastBar?.close.toString() ?? "market price"}
                                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    {symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""}
                                </div>
                            </div>
                            {
                                errors.price
                                    ? <div>
                                        <p className="text-red-500">{errors.price}</p>
                                    </div>
                                    : null
                            }
                            <div className="mt-3">
                                <div key="limit" className="flex items-center">
                                    <input
                                        defaultChecked={true}
                                        id="limit"
                                        name="orderType"
                                        type="radio"
                                        value="limit"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            values.orderType = e.target.value;
                                            values.price = lastBar?.close;
                                            handleChange(e)
                                        }}
                                    />
                                    <label htmlFor="limit" className="ml-3 block text-sm font-medium leading-6 text-gray-50">
                                        Limit Order
                                    </label>
                                </div>
                                <div key="market" className="flex items-center">
                                    <input
                                        defaultChecked={false}
                                        id="market"
                                        value="market"
                                        name="orderType"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            values.orderType = e.target.value;
                                            values.price = lastBar?.close;
                                            handleChange(e)
                                        }}
                                    />
                                    <label htmlFor="market" className="ml-3 block text-sm font-medium leading-6 text-gray-50">
                                        Market Order
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-row justify-between space-x-2'>
                            <div>
                                <label htmlFor="takeProfit" className="block text-sm font-medium leading-6 text-gray-50">
                                    Profit Target
                                </label>
                                <div className="relative mt-2 rounded-md shadow-sm">
                                    <input
                                        id="takeProfit"
                                        name="takeProfit"
                                        type="number"
                                        step="any"
                                        placeholder="0"
                                        value={values.takeProfit}
                                        className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />

                                </div>
                            </div>
                            <div>
                                <label htmlFor="stopLoss" className="block text-sm font-medium leading-6 text-gray-50">
                                    Stop Loss
                                </label>
                                <div className="relative mt-2 rounded-md shadow-sm">
                                    <input
                                        id="stopLoss"
                                        name="stopLoss"
                                        type="number"
                                        step="any"
                                        placeholder="0"
                                        value={values.stopLoss}
                                        className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        errors.api
                            ? <div>
                                <p className="text-red-500">{errors.api}</p>
                            </div>
                            : null
                    }
                    <div className='flex flex-row space-x-2 pt-6 items-center justify-stretch'>
                        {
                            isOpenOrder
                                ? <button
                                    type="submit"
                                    className="flex-1 rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
                                    onClick={(e) => {
                                        setShowNotification(false);
                                        setTimeout(() => {
                                            setShowNotification(false);
                                        }, 3000);

                                        values.side = "buy_long";
                                        validate(values)
                                    }}
                                >
                                    Buy Long
                                </button>
                                : <button
                                    type="submit"
                                    className="flex-1 rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                                    onClick={(e) => {
                                        setShowNotification(false);
                                        setTimeout(() => {
                                            setShowNotification(false);
                                        }, 3000);

                                        values.side = "sell_long";
                                        validate(values)
                                    }}
                                >
                                    Close Long
                                </button>
                        }


                        {
                            isOpenOrder
                                ? <button
                                    type="submit"
                                    className="flex-1 rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                                    onClick={(e) => {
                                        setShowNotification(false);
                                        setTimeout(() => {
                                            setShowNotification(false);
                                        }, 3000);

                                        values.side = "sell_short";
                                        validate(values)
                                    }}
                                >
                                    Sell Short
                                </button>
                                : <button
                                    type="submit"
                                    className="flex-1 rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
                                    onClick={(e) => {
                                        setShowNotification(false);
                                        setTimeout(() => {
                                            setShowNotification(false);
                                        }, 3000);

                                        values.side = "buy_short";
                                        validate(values)
                                    }}
                                >
                                    Close Short
                                </button>
                        }

                    </div>
                    <div>
                        <p></p>
                        <p></p>
                    </div>
                </form >
                <BalanceDisplay />
            </div>
        </>
    )
}