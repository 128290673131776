import { ChartData, ChartMetaInfo, ChartTemplate, ChartTemplateContent, IExternalSaveLoadAdapter, LineToolsAndGroupsLoadRequestContext, LineToolsAndGroupsLoadRequestType, LineToolsAndGroupsState, ResolutionString, StudyTemplateData, StudyTemplateMetaInfo } from "charting_library";
import { test_chart_data } from "./test_chart_data";
import IChartManager from "./manager/chart_manager_base";

class ReplaySaveLoadAdapter<T extends IChartManager> implements IExternalSaveLoadAdapter {
    public loadingAllCharts = false;
    public sessionLastChartId: number | undefined = undefined;

    constructor(protected readonly manager: T) { }

    async getAllCharts(): Promise<ChartMetaInfo[]> {
        if (!this.loadingAllCharts) {
            this.loadingAllCharts = true;
            const path = window.location.pathname.split("/");
            const sessionId = path[path.length - 1];
            const chartData = await this.manager.listSessionChartLayouts(sessionId)

            return this.sessionLastChartId != undefined
                ? chartData.filter((chartData) => chartData.id == this.sessionLastChartId)
                : chartData;
        }

        const chartData = await this.manager.listAllChartLayouts();
        return chartData;
    }

    async removeChart<T extends number | string>(id: T): Promise<void> {
        const path = window.location.pathname.split("/");
        const sessionId = path[path.length - 1];

        await this.manager.deleteChartLayout(id as number, sessionId)
    }

    async saveChart(chartData: ChartData): Promise<string> {
        const path = window.location.pathname.split("/");
        const sessionId = path[path.length - 1];

        const chartId = await this.manager.saveChartLayout(chartData.name, sessionId, chartData.content, chartData.id);

        return chartId;
    }

    async getChartContent(chartId: number): Promise<string> {
        const path = window.location.pathname.split("/");
        const sessionId = path[path.length - 1];

        const chartContent = await this.manager.loadChartLayout(chartId, sessionId);

        return chartContent;
    }

    async getAllStudyTemplates(): Promise<StudyTemplateMetaInfo[]> {
        throw new Error("Method getAllStudyTemplates not implemented.");
    }

    async removeStudyTemplate(studyTemplateInfo: StudyTemplateMetaInfo): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async saveStudyTemplate(studyTemplateData: StudyTemplateData): Promise<void> {
        console.log(studyTemplateData);
        throw new Error("Method not implemented.");
    }

    async getStudyTemplateContent(studyTemplateInfo: StudyTemplateMetaInfo): Promise<string> {
        console.log(studyTemplateInfo);
        throw new Error("Method not implemented.");
    }

    async getDrawingTemplates(toolName: string): Promise<string[]> {
        console.log(toolName);
        throw new Error("Method not implemented.");
    }

    async loadDrawingTemplate(toolName: string, templateName: string): Promise<string> {
        throw new Error("Method loadDrawingTemplate not implemented.");
    }

    async removeDrawingTemplate(toolName: string, templateName: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async saveDrawingTemplate(toolName: string, templateName: string, content: string): Promise<void> {
        console.log(toolName, templateName, content)
        throw new Error("Method loadDrawingTemplate not implemented.");
    }

    async getChartTemplateContent(templateName: string): Promise<ChartTemplate> {
        console.log("got template content", templateName)
        throw new Error("Method getChartTemplateContent not implemented.");
    }

    async getAllChartTemplates(): Promise<string[]> {
        console.log("getting all chart templates")
        throw new Error("Method getAllChartTemplates not implemented.");
    }

    async saveChartTemplate(newName: string, theme: ChartTemplateContent): Promise<void> {
        console.log(newName, theme)
        throw new Error("Method not implemented.");
    }

    async removeChartTemplate(templateName: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async saveLineToolsAndGroups(layoutId: string | undefined, chartId: string | number, state: LineToolsAndGroupsState): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async loadLineToolsAndGroups(layoutId: string | undefined, chartId: string | number, requestType: LineToolsAndGroupsLoadRequestType, requestContext: LineToolsAndGroupsLoadRequestContext): Promise<Partial<LineToolsAndGroupsState> | null> {
        throw new Error("Method not implemented.");
    }

}

export default ReplaySaveLoadAdapter;