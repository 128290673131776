import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { backtestingManagerState } from "../../../../../../state/backtesting/backtesting_manager_state";
import { useEffect, useState } from "react";
import { currentStrategyState } from "../../../../../../state/backtesting/atoms/current_strategy";
import { tradesState } from "../../../../../../state/backtesting/atoms/trades";
import { currentSessionState } from "../../../../../../state/backtesting/atoms/current_session";
import { sessionsState } from "../../../../../../state/backtesting/atoms/sessions";
import { Order } from "../../../../../../state/backtesting/models/backtesting_models";
import { lastBarState } from "../../../../../../state/replay/atoms/lastBar";
import { ordersState } from "../../../../../../state/backtesting/atoms/orders";
import { strategyTradesSelector } from "../../../../../../state/backtesting/selectors/trades_selector";
import { sessionsSelector } from "../../../../../../state/backtesting/selectors/sessions_selector";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function StrategyTrades() {
    const navigate = useNavigate();
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const currentStrategy = useRecoilValue(currentStrategyState);
    const setTrades = useSetRecoilState(tradesState);
    const strategyTrades = useRecoilValue(strategyTradesSelector(currentStrategy?.id ?? ""));
    const strategySessions = useRecoilValue(sessionsSelector(currentStrategy?.id ?? ""));
    const setCurrentSession = useSetRecoilState(currentSessionState);
    const [orders, setOrders] = useRecoilState(ordersState);
    const setSessions = useSetRecoilState(sessionsState)

    useEffect(() => {
        if (currentStrategy != undefined) {
            console.log(currentStrategy);
            backtestingManager.listSessions(setSessions, undefined, currentStrategy.id)
            backtestingManager.listStrategyTrades(setTrades, currentStrategy.id);
        }
    }, [currentStrategy]);

    useEffect(() => {
        if (strategyTrades.length > 0) {
            strategySessions.forEach((session) => {
                backtestingManager.listOrders(setOrders, session.id)
            })
        }
    }, [strategyTrades])

    const computeTradeAvgEntryPrice = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("buy") &&
            o.state == "passed"
        );

        let amountCount = 0;
        let priceCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
            priceCount += order.price * order.amount
        }

        return (priceCount / amountCount);
    }

    const computeTradeAvgExitPrice = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("sell") &&
            o.state == "passed"
        );

        let amountCount = 0;
        let priceCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
            priceCount += order.price * order.amount
        }

        return (priceCount / amountCount);
    }

    const computeTradeAmountBought = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("buy") &&
            o.state == "passed"
        );

        let amountCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
        }

        return amountCount;
    }

    const computeTradeAmountSold = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("sell") &&
            o.state == "passed"
        );

        let amountCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
        }

        return amountCount;
    }

    const computeTradeRealisedPl = (tradeId: string, or: Order[]) => {
        const amountSold = computeTradeAmountSold(tradeId, or);
        const avgEntry = computeTradeAvgEntryPrice(tradeId, or);
        const avgExit = computeTradeAvgExitPrice(tradeId, or);

        return (avgExit - avgEntry) * amountSold;
    }

    return (
        <>
            <div className="flex flex-row justify-between items-baseline mt-10 mb-3">
                <h2 className="text-gray-50 pt-3 pb-2 text-3xl">Trades</h2>
            </div>
            <div>
                <div className="bg-gray-900">
                    <div className="mx-auto max-w-9xl">
                        <div className="bg-gray-900 py-10">
                            <div className="px-4 sm:px-6 lg:px-6">
                                <div className="-mx-4 mt-10 ring-1 ring-gray-700 sm:mx-0 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-700">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Session
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Product
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Side
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Status
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Avg Entry Price
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Avg Exit Price
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Amount Bought
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Amount Sold
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Realised P&L
                                                </th>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                                                    Entry Time
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Exit Time
                                                </th>
                                                <th scope="col" className="py-3.5 pl-3">
                                                    <span className="sr-only">Details</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-800">
                                            {strategyTrades.filter((trade) => trade.status == "closed").map((trade) => (
                                                <tr key={trade.id}>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                        {strategySessions.find((session) => session.id == trade.sessionId)?.name ?? "-"}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{trade.product}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{trade.side}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{trade.status}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                        {computeTradeAvgEntryPrice(trade.id, orders.get(trade.id) ?? []).toFixed(3)}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                        {computeTradeAvgExitPrice(trade.id, orders.get(trade.id) ?? []).toFixed(3)}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                        {computeTradeAmountBought(trade.id, orders.get(trade.id) ?? [])}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                        {computeTradeAmountSold(trade.id, orders.get(trade.id) ?? []).toFixed(3)}
                                                    </td>
                                                    <td className={classNames(
                                                        "whitespace-nowrap px-3 py-4 text-sm",
                                                        computeTradeRealisedPl(trade.id, orders.get(trade.id) ?? []) > 0
                                                            ? "text-green-400"
                                                            : (
                                                                computeTradeRealisedPl(trade.id, orders.get(trade.id) ?? []) < 0
                                                                    ? "text-red-500"
                                                                    : "text-gray-300"
                                                            )
                                                    )}>
                                                        {computeTradeRealisedPl(trade.id, orders.get(trade.id) ?? []).toFixed(3)}
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-white sm:pl-0">
                                                        {new Date(trade.entryTime * 1000).toUTCString()}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                        {trade.exitTime == 0 ? "-" : new Date(trade.exitTime * 1000).toUTCString()}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                        <a href="#" className="text-indigo-400 hover:text-indigo-300">
                                                            Details<span className="sr-only">, {trade.id}</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}