import { useRecoilState, useRecoilValue } from "recoil"
import { tradesState } from "../../../../../state/backtesting/atoms/trades"
import { ordersState } from "../../../../../state/backtesting/atoms/orders";
import { Order } from "../../../../../state/backtesting/models/backtesting_models";
import { useEffect } from "react";
import { lastBarState } from "../../../../../state/replay/atoms/lastBar";
import { currentSessionState } from "../../../../../state/backtesting/atoms/current_session";
import { backtestingManagerState } from "../../../../../state/backtesting/backtesting_manager_state";
import { sessionOrdersSelector } from "../../../../../state/backtesting/selectors/orders_selectors";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function PassedTrades() {
    const [trades, setTrades] = useRecoilState(tradesState);
    const [orders, setOrders] = useRecoilState(ordersState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const currentSession = useRecoilValue(currentSessionState);
    const sessionOrders = useRecoilValue(sessionOrdersSelector(currentSession?.id ?? ""));

    useEffect(() => {
        if (currentSession != undefined) {
            backtestingManager.listSessionTrades(setTrades, currentSession.id);
        }
    }, [orders]);

    const computeTradeAvgEntryPrice = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("buy") &&
            o.state == "passed"
        );

        let amountCount = 0;
        let priceCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
            priceCount += order.price * order.amount
        }

        return (priceCount / amountCount);
    }

    const computeTradeAvgExitPrice = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("sell") &&
            o.state == "passed"
        );

        let amountCount = 0;
        let priceCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
            priceCount += order.price * order.amount
        }

        return (priceCount / amountCount);
    }

    const computeTradeAmountBought = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("buy") &&
            o.state == "passed"
        );

        let amountCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
        }

        return amountCount;
    }

    const computeTradeAmountSold = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("sell") &&
            o.state == "passed"
        );

        let amountCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
        }

        return amountCount;
    }

    const computeTradeRealisedPl = (tradeId: string, or: Order[]) => {
        const amountSold = computeTradeAmountSold(tradeId, or);
        const avgEntry = computeTradeAvgEntryPrice(tradeId, or);
        const avgExit = computeTradeAvgExitPrice(tradeId, or);

        return (avgExit - avgEntry) * amountSold;
    }

    return (
        <div className="bg-gray-950">
            <div>
                <div className="bg-gray-950">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full">
                            <table className="min-w-full divide-y divide-gray-700">
                                <thead>
                                    <tr>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Side
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Status
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Avg Entry Price
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Avg Exit Price
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Amount Bought
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Amount Sold
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Realised P&L
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                                            Entry Time
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                            Exit Time
                                        </th>
                                        <th scope="col" className="py-3.5 pl-3">
                                            <span className="sr-only">Details</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-800">
                                    {trades.get(currentSession?.id ?? "")?.filter((trade) => trade.status == "closed").map((trade) => (
                                        <tr key={trade.id}>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{trade.side}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{trade.status}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {computeTradeAvgEntryPrice(trade.id, sessionOrders).toFixed(3)}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {computeTradeAvgExitPrice(trade.id, sessionOrders).toFixed(3)}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {computeTradeAmountBought(trade.id, sessionOrders)}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {computeTradeAmountSold(trade.id, sessionOrders).toFixed(3)}
                                            </td>
                                            <td className={classNames(
                                                "whitespace-nowrap px-3 py-4 text-sm",
                                                computeTradeRealisedPl(trade.id, sessionOrders) > 0
                                                    ? "text-green-400"
                                                    : (computeTradeRealisedPl(trade.id, sessionOrders) < 0 ? "text-red-500" : "text-gray-300")
                                            )}>
                                                {computeTradeRealisedPl(trade.id, sessionOrders).toFixed(3)}
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-white sm:pl-0">
                                                {new Date(trade.entryTime * 1000).toUTCString()}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                {trade.exitTime == 0 ? "-" : new Date(trade.exitTime * 1000).toUTCString()}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                <a href="#" className="text-indigo-400 hover:text-indigo-300">
                                                    Details<span className="sr-only">, {trade.id}</span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}