'use client'

import { Dispatch, SetStateAction, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil'
import { Order, RuleGroup } from '../../../../../../state/backtesting/models/backtesting_models'
import IBacktestingManagerBase from '../../../../../../state/backtesting/manager/backtesting_manager_base'
import { backtestingManagerState } from '../../../../../../state/backtesting/backtesting_manager_state'
import { tradesState } from '../../../../../../state/backtesting/atoms/trades'

export default function DeleteTPSLModal(props: {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    tradeId: string,
    slId?: string,
    tpId?: string
}) {
    const setTradesState = useSetRecoilState(tradesState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const [deleteTp, setDeleteTp] = useState(true);
    const [deleteSl, setDeleteSl] = useState(true);

    return (
        <Dialog open={props.open} onClose={props.setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-transparent backdrop-blur-md transition-all data-[closed]:backdrop-blur-none data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Delete Take Profit / Stop Loss
                                </DialogTitle>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Click Delete to Confirm your choice.
                                    </p>
                                </div>
                                <div className="space-y-5 mt-4">
                                    {
                                        props.tpId != undefined
                                            ? <div className="relative flex items-start">
                                                <div className="flex h-6 items-center">
                                                    <input
                                                        id="delete-tp"
                                                        name="delete-tp"
                                                        type="checkbox"
                                                        aria-describedby="delete-tp-description"
                                                        disabled={props.tpId == undefined}
                                                        checked={deleteTp}
                                                        onChange={(e) => setDeleteTp(!deleteTp)}
                                                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm leading-6">
                                                    <label htmlFor="delete-tp-description" className="font-medium text-gray-900">
                                                        Delete Take Profit
                                                    </label>{' '}
                                                    <span id="comments-description" className="text-gray-500">
                                                        <span className="sr-only">Delete Take Profit </span>
                                                    </span>
                                                </div>
                                            </div>
                                            : null
                                    }


                                    {
                                        props.slId != undefined
                                            ? <div className="relative flex items-start">
                                                <div className="flex h-6 items-center">
                                                    <input
                                                        id="delete-sl"
                                                        name="delete-sl"
                                                        type="checkbox"
                                                        aria-describedby="delete-sl-description"
                                                        disabled={props.slId == undefined}
                                                        checked={deleteSl}
                                                        onChange={(e) => setDeleteSl(!deleteSl)}
                                                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm leading-6">
                                                    <label htmlFor="candidates" className="font-medium text-gray-900">
                                                        Delete Stop Loss
                                                    </label>{' '}
                                                    <span id="delete-sl-description" className="text-gray-500">
                                                        <span className="sr-only">Delete Stop Loss </span>
                                                    </span>
                                                </div>
                                            </div>
                                            : null
                                    }

                                </div>
                            </div>

                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                onClick={async () => {
                                    if (deleteTp) {
                                        await backtestingManager.deleteTakeProfit(setTradesState, props.tradeId, props.tpId!)
                                    }

                                    if (deleteSl) {
                                        await backtestingManager.deleteStopLoss(setTradesState, props.tradeId, props.slId!)
                                    }

                                    props.setOpen(false);

                                    setTimeout(() => {
                                        setDeleteSl(true);
                                        setDeleteTp(true);
                                    }, 500)
                                }}
                                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => props.setOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Back
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}