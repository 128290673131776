import axios, { AxiosInstance } from "axios";
import IStatisticsAPIBase from "./statistics_api_base";
import { SessionFilterSettings, DaysFilterSettings, TagsFilterSettings, RulesFilterSettings, StrategyStatistics, SessionStatistics, RuleStatistics, TradeStatistics } from "../models/statistics_models";
import { signWithToken } from "../../../utils/crypto";

export default class StatisticsAPI implements IStatisticsAPIBase {
    protected instance: AxiosInstance;

    constructor(domain: string, port: string) {
        this.instance = axios.create({
            baseURL: `${domain}:${port}/api/statistics`,
        });
    }


    async generateStrategyStatistics(strategyId: string, sessionId: string, sessionFilter?: SessionFilterSettings, daysFilter?: DaysFilterSettings, tagsFilter?: TagsFilterSettings, rulesFilter?: RulesFilterSettings): Promise<StrategyStatistics> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(
            `/strategy/${strategyId}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const strategyStats: StrategyStatistics = Object.assign(
            new StrategyStatistics("", "", "", new Map(), ""),
            res.data
        );

        return strategyStats;
    }

    async generateSessionStatistics(sessionId: string, sessionFilter?: SessionFilterSettings, daysFilter?: DaysFilterSettings, tagsFilter?: TagsFilterSettings, rulesFilter?: RulesFilterSettings): Promise<SessionStatistics> {
        throw new Error("Method not implemented.");
    }

    async generateRuleStatistics(ruleId: string): Promise<RuleStatistics> {
        throw new Error("Method not implemented.");
    }

    async generateTradeStatistics(tradeId: string): Promise<TradeStatistics> {
        throw new Error("Method not implemented.");
    }
}