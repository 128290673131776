import { useEffect } from "react"
import { useRecoilValue } from "recoil";
import { tradesState } from "../../../../../../../state/backtesting/atoms/trades";
import { ordersState } from "../../../../../../../state/backtesting/atoms/orders";
import { currentSessionState } from "../../../../../../../state/backtesting/atoms/current_session";
import { lastBarState } from "../../../../../../../state/replay/atoms/lastBar";
import { Order } from "../../../../../../../state/backtesting/models/backtesting_models";
import { symbolsState } from "../../../../../../../state/backtesting/atoms/symbols";
import { sessionOrdersSelector } from "../../../../../../../state/backtesting/selectors/orders_selectors";

const stats = [
    { name: 'Balance', value: 1200 },
    { name: 'Asset Balance', value: 45000 },
    { name: 'Amount Owed', value: 350 },
    { name: 'Available Equity', value: 879 },
]

export default function BalanceDisplay() {
    const currentSession = useRecoilValue(currentSessionState);
    const orders = useRecoilValue(sessionOrdersSelector(currentSession?.id ?? ""));
    const lastBar = useRecoilValue(lastBarState);
    const symbols = useRecoilValue(symbolsState);

    useEffect(() => {

    }, [orders]);

    const computeTotalEquity = (lastPrice: number, assetAmount: number, accountBalance: number, isShort: boolean): number => {
        if (isShort) {
            return Math.round(Math.round((accountBalance - (lastPrice * assetAmount)) * 100)) / 100
        }
        return Math.round((lastPrice * assetAmount + accountBalance) * 100) / 100;
    }

    const computeAvailableEquity = (accountBalance: number, pendingOrders: Order[]): number => {
        let availabeEquity = accountBalance;

        for (let i = 0; i < pendingOrders.length; i++) {
            const openOrder = pendingOrders[i];

            if (openOrder.side == "buy_long" || openOrder.side == "sell_short") {
                availabeEquity -= openOrder.amount * openOrder.price;
            }
        }

        availabeEquity -= currentSession!.amountOwed * lastBar!.close

        return Math.round(availabeEquity * 100) / 100;
    }

    return (
        <div className="bg-gray-900 border-t border-t-gray-800 py-2">
            <div className="mx-auto max-w-7xl">
                <div className="grid grid-cols-1 gap-px  md:grid-cols-1 lg:grid-cols-2">

                    <div key="balance" className="bg-gray-900 px-2 py-2 sm:px-2 lg:px-4">
                        <p className="text-sm font-medium leading-2 text-gray-400">Balance</p>
                        <p className="mt-2 flex items-baseline gap-x-1">
                            <span className="text-xl font-semibold tracking-tight text-white">{currentSession?.accountBalance ?? "-"}</span>
                            <span className="text-sm text-gray-400">
                                {
                                    currentSession != undefined
                                        ? symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""
                                        : null
                                }
                            </span>
                        </p>
                    </div>

                    <div key="assetBalance" className="bg-gray-900 px-2 py-2 sm:px-2 lg:px-4">
                        <p className="text-sm font-medium leading-2 text-gray-400">Asset Balance</p>
                        <p className="mt-2 flex items-baseline gap-x-1">
                            <span className="text-xl font-semibold tracking-tight text-white">{currentSession?.assetAmount ?? "-"}</span>
                            <span className="text-sm text-gray-400">
                                {
                                    currentSession != undefined
                                        ? symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? ""
                                        : null
                                }
                            </span>
                        </p>
                    </div>

                    <div key="amountOwed" className="bg-gray-900 px-2 py-2 sm:px-2 lg:px-4">
                        <p className="text-sm font-medium leading-2 text-gray-400">Amount Owed</p>
                        <p className="mt-2 flex items-baseline gap-x-1">
                            <span className="text-xl font-semibold tracking-tight text-white">{currentSession?.amountOwed ?? "-"}</span>
                            <span className="text-sm text-gray-400">
                                {
                                    currentSession != undefined
                                        ? symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? ""
                                        : null
                                }
                            </span>
                        </p>
                    </div>

                    <div key="availableEquity" className="bg-gray-900 px-2 py-2 sm:px-2 lg:px-4">
                        <p className="text-sm font-medium leading-2 text-gray-400">Available Equity</p>
                        <p className="mt-2 flex items-baseline gap-x-1">
                            <span className="text-xl font-semibold tracking-tight text-white">
                                {
                                    currentSession != undefined && lastBar != undefined
                                        ? computeAvailableEquity(
                                            currentSession.accountBalance,
                                            orders.filter((order) => order.state == "awaiting")
                                        )
                                        : "-"}
                            </span>
                            <span className="text-sm text-gray-400">
                                {
                                    currentSession != undefined
                                        ? symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""
                                        : null
                                }
                            </span>
                        </p>
                    </div>
                    <div key="totalEquity" className="bg-gray-900 px-2 py-2 sm:px-2 lg:px-4">
                        <p className="text-sm font-medium leading-2 text-gray-400">Total Equity</p>
                        <p className="mt-2 flex items-baseline gap-x-1">
                            <span className="text-xl font-semibold tracking-tight text-white">
                                {
                                    currentSession != undefined && lastBar != undefined
                                        ? computeTotalEquity(lastBar.close, currentSession.amountOwed > 0 ? currentSession.amountOwed : currentSession.assetAmount, currentSession.accountBalance, currentSession.amountOwed > 0)
                                        : "-"
                                }
                            </span>
                            <span className="text-sm text-gray-400">
                                {
                                    currentSession != undefined
                                        ? symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""
                                        : null
                                }
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}