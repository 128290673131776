import { useRecoilState, useRecoilValue } from "recoil"
import { statisticsManagerState } from "../../../../../../state/statistics/statistics_manager_state"
import { strategyStatisticsState } from "../../../../../../state/statistics/atoms/strategy_statistics";
import { useEffect } from "react";
import { currentStrategyState } from "../../../../../../state/backtesting/atoms/current_strategy";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, AreaChart, Area, Brush, Legend } from 'recharts';
import { BalanceMovement, TimedMetric } from "../../../../../../state/statistics/models/statistics_models";

export default function StrategyStatistics() {
    const currentStrategy = useRecoilValue(currentStrategyState);
    const statisticsManager = useRecoilValue(statisticsManagerState);
    const [strategyStats, setStrategyStats] = useRecoilState(strategyStatisticsState);

    useEffect(() => {
        if (currentStrategy != undefined)
            statisticsManager.generateStrategyStatistics(setStrategyStats, currentStrategy!.id, "")
                .then((stats) => console.log(stats));
    }, [currentStrategy]);

    const renderEquityCurve = (equityCurve: TimedMetric[]) => {
        const dataMax = Math.max(...equityCurve.map((i) => i.value));
        const dataMin = Math.min(...equityCurve.map((i) => i.value));

        return (
            <AreaChart width={1200} height={700} data={equityCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <Area type="natural" dataKey="value" stroke="#8884d8" fill="#8884d8" />

                <XAxis dataKey="date" label={"date"} />
                <YAxis dataKey="value" domain={[dataMin, dataMax]} origin={equityCurve[0]?.value ?? 0} label={"equity"} />
                <Tooltip />
                <Brush data={equityCurve} />
                <Legend />
            </AreaChart>
        );
    }

    const renderDrawdownCurve = (equityCurve: TimedMetric[]) => (
        <LineChart width={1200} height={700} data={equityCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <Line type="natural" dataKey="value" stroke="#8884d8" connectNulls={true} />

            <XAxis dataKey="date" label={"date"} />
            <YAxis dataKey="value" label={"drawdown"} />
            <Tooltip />
            <Brush data={equityCurve} />
            <Legend />
        </LineChart>
    );

    const renderIdealRiskRewardCurve = (equityCurve: TimedMetric[]) => (
        <LineChart width={1200} height={700} data={equityCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <Line type="natural" dataKey="value" stroke="#8884d8" />

            <XAxis dataKey="date" />
            <YAxis dataKey="value" />
            <Tooltip />
            <Brush data={equityCurve} />
            <Legend />
        </LineChart>
    );

    const renderRiskRewardCurve = (equityCurve: TimedMetric[]) => (
        <LineChart width={1200} height={700} data={equityCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <Line type="natural" dataKey="value" stroke="#8884d8" connectNulls={true} />

            <XAxis dataKey="date" label={"date"} />
            <YAxis dataKey="value" label={"risk reward"} />
            <Tooltip />
            <Brush data={equityCurve} />
            <Legend content={<>risk reward</>} />
        </LineChart>
    );

    const renderDailyReturns = (equityCurve: TimedMetric[]) => {
        const gradientOffset = () => {
            const dataMax = Math.max(...equityCurve.map((i) => i.value));
            const dataMin = Math.min(...equityCurve.map((i) => i.value));

            if (dataMax <= 0) {
                return 0;
            }
            if (dataMin >= 0) {
                return 1;
            }

            return dataMax / (dataMax - dataMin);
        };

        const off = gradientOffset();

        return (
            <AreaChart width={1200} height={700} data={equityCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" label={"date"} />
                <YAxis dataKey="value" label={"daily return"} />
                <Tooltip />

                <defs>
                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset={off} stopColor="green" stopOpacity={1} />
                        <stop offset={off} stopColor="red" stopOpacity={1} />
                    </linearGradient>
                </defs>

                <Area type="monotone" dataKey="value" stroke="url(#splitColor)" fill="url(#splitColor)" />
                <Brush data={equityCurve} />
                <Legend content={<>daily returns</>} />
            </AreaChart>
        );
    }

    const renderBalanceMovement = (equityCurve: BalanceMovement[]) => (
        <AreaChart width={1200} height={700} data={equityCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

            <XAxis dataKey="date" label={"date"} />
            <YAxis yAxisId="left" label={"balance"} />
            <YAxis yAxisId="right" orientation="right" label={"traded assets balance"} />

            <Tooltip />
            <Legend />

            <Area yAxisId="left" type="monotone" dataKey="balance" fill="#8884d8" stroke="#8884d8" />
            <Area yAxisId="right" type="monotone" dataKey="assetAmount" fill="#82ca9d" stroke="#82ca9d" />
            <Area yAxisId="right" type="monotone" dataKey="amountOwed" fill="#f08080" stroke="#f08080" />
            <Brush data={equityCurve} />
        </AreaChart>
    );

    return (
        <div className="flex flex-col justify-center space-y-20  items-center">
            <div className="mt-16">
                {
                    strategyStats.get(currentStrategy?.id ?? "none") != undefined
                        ? renderEquityCurve(strategyStats.get(currentStrategy!.id)!.equityCurve)
                        : <p>Loading...</p>
                }
            </div>
            <div>
                {
                    strategyStats.get(currentStrategy?.id ?? "none") != undefined
                        ? renderDailyReturns(strategyStats.get(currentStrategy!.id)!.returns)
                        : <p>Loading...</p>
                }
            </div>
            <div>
                {
                    strategyStats.get(currentStrategy?.id ?? "none") != undefined
                        ? renderRiskRewardCurve(strategyStats.get(currentStrategy!.id)!.riskRewardCurve)
                        : <p>Loading...</p>
                }
            </div>
            <div>
                {
                    strategyStats.get(currentStrategy?.id ?? "none") != undefined
                        ? renderDrawdownCurve(strategyStats.get(currentStrategy!.id)!.drawdownCurve)
                        : <p>Loading...</p>
                }
            </div>
            <div>
                {
                    strategyStats.get(currentStrategy?.id ?? "none") != undefined
                        ? renderBalanceMovement(strategyStats.get(currentStrategy!.id)!.balanceMovement)
                        : <p>Loading...</p>
                }
            </div>
        </div>
    )
}