import { Dispatch, SetStateAction, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Label, Switch } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil'
import { Field, useFormik } from 'formik'
import { currentSessionState } from '../../../../../../state/backtesting/atoms/current_session'
import { symbolsState } from '../../../../../../state/backtesting/atoms/symbols'
import { lastBarState } from '../../../../../../state/replay/atoms/lastBar'
import { Qty, StopLoss, TakeProfit, TPSL } from '../../../../../../state/backtesting/models/backtesting_models'
import { backtestingManagerState } from '../../../../../../state/backtesting/backtesting_manager_state'
import { tradesState } from '../../../../../../state/backtesting/atoms/trades'

export default function EditTPSLModal(props: {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    tradeId: string,
    tpsl?: TPSL
}) {

    const currentSession = useRecoilValue(currentSessionState);
    const symbols = useRecoilValue(symbolsState);
    const lastBar = useRecoilValue(lastBarState);
    const [tpQtyPercentage, setTpQtyPercentage] = useState(false);
    const [slQtyPercentage, setSlQtyPercentage] = useState(false);
    const setTradeState = useSetRecoilState(tradesState);

    const backtestingManager = useRecoilValue(backtestingManagerState);

    const validate = (values: { tpPrice?: number, tpQty?: Qty, slPrice?: number, slQty?: Qty, api?: string }) => {
        const errors: { name?: string, description?: string, api?: string } = {};


        return errors;
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
    } = useFormik({
        initialValues: {
            tpPrice: props.tpsl?.takeProfit?.price,
            tpQty: props.tpsl?.takeProfit?.Qty,
            slPrice: props.tpsl?.stopLoss?.price,
            slQty: props.tpsl?.stopLoss?.Qty,
        },
        validate,
        onSubmit: async (values) => {
            console.log(values);
            try {
                let tp = undefined;
                if (props.tpsl?.takeProfit != undefined) {
                    tp = new TakeProfit(
                        props.tpsl?.takeProfit.price,
                        props.tpsl?.takeProfit.Qty,
                        props.tpsl?.takeProfit.created,
                        props.tpsl?.takeProfit.createdSessionTime,
                        props.tpsl?.takeProfit.triggered
                    );

                    if (values.tpPrice != undefined) {
                        tp.price = values.tpPrice;
                    }

                    if (values.tpQty != undefined) {
                        tp.Qty = values.tpQty;
                    }
                } else if (values.tpPrice != undefined && values.tpQty != undefined) {
                    tp = new TakeProfit(
                        values.tpPrice,
                        values.tpQty,
                        Math.round(Date.now() / 1000),
                        lastBar!.time / 1000,
                        false)

                }

                let sl = undefined;
                if (props.tpsl?.stopLoss != undefined) {
                    sl = new StopLoss(
                        props.tpsl?.stopLoss.price,
                        props.tpsl?.stopLoss.Qty,
                        props.tpsl?.stopLoss.created,
                        props.tpsl?.stopLoss.createdSessionTime,
                        props.tpsl?.stopLoss.triggered
                    );
                    if (values.slPrice != undefined) {
                        sl.price = values.slPrice;
                    }

                    if (values.slQty != undefined) {
                        sl.Qty = values.slQty;
                    }
                } else if (values.slPrice != undefined && values.slQty != undefined) {
                    sl = new StopLoss(
                        values.slPrice,
                        values.slQty,
                        Math.round(Date.now() / 1000),
                        lastBar!.time / 1000,
                        false)

                }
                await backtestingManager.updateTrade(setTradeState, props.tradeId, new TPSL(props.tpsl!.id, tp, sl));
                props.setOpen(false);

                values.api = undefined;
                values.slPrice = undefined;
                values.slQty = undefined;
                values.tpPrice = undefined;
                values.tpQty = undefined;

                return true;
            } catch (error) {
                errors.api = "failed to create strategy"
                return false;
            }
        }
    });

    const convertQtyToString = (qty: Qty | undefined, isPercentage: boolean): string | undefined => {
        if (qty == undefined) {
            return;
        }

        if (qty.FixedAmount != undefined) {
            return qty.FixedAmount.toString();
        }

        if (qty.Percentage != undefined) {
            return qty.Percentage.toString();
        }
    }

    return (
        <Dialog open={props.open} onClose={props.setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-transparent backdrop-blur-md transition-all data-[closed]:backdrop-blur-none data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <form onSubmit={handleSubmit}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-start sm:items-center sm:p-0">

                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-md sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mt-1 sm:mt-3">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        Edit Take Profit / Stop Loss
                                    </DialogTitle>

                                    <div className="mt-6 flex flex-col space-y-6">
                                        <div>
                                            <label htmlFor="sl-price" className="block text-sm font-medium leading-6 text-gray-900">
                                                Take Profit
                                            </label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input
                                                    id="tpPrice"
                                                    name="tpPrice"
                                                    type="number"
                                                    step="any"
                                                    placeholder={props.tpsl?.takeProfit?.price?.toString() ?? "take profit price"}
                                                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    {symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""}
                                                </div>
                                            </div>


                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input
                                                    id="tpQty"
                                                    name="tpQty"
                                                    type="number"
                                                    step="any"
                                                    max={tpQtyPercentage ? 100 : "any"}
                                                    min={tpQtyPercentage ? 0 : "any"}
                                                    placeholder={convertQtyToString(props.tpsl?.takeProfit?.Qty, tpQtyPercentage) ?? "qty"}
                                                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        if (tpQtyPercentage) {
                                                            values.tpQty = new Qty(undefined, parseFloat(e.target.value))
                                                        } else {
                                                            values.tpQty = new Qty(parseFloat(e.target.value))
                                                        }
                                                    }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    {tpQtyPercentage ? "%" : symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? ""}
                                                </div>
                                            </div>

                                            <Switch
                                                checked={tpQtyPercentage}
                                                onChange={setTpQtyPercentage}
                                                className="group mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
                                            >
                                                <span
                                                    aria-hidden="true"
                                                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                />
                                            </Switch>
                                            <label className="ml-3 text-sm mt-2">
                                                <span className="font-medium text-gray-900">Set Quantity as a Percentage</span>
                                            </label>

                                        </div>
                                        <div>
                                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                Stop Loss
                                            </label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input
                                                    id="slPrice"
                                                    name="slPrice"
                                                    type="number"
                                                    step="any"
                                                    placeholder={props.tpsl?.stopLoss?.price?.toString() ?? "stop loss price"}
                                                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    {symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""}
                                                </div>
                                            </div>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input
                                                    id="slQty"
                                                    name="slQty"
                                                    type="number"
                                                    step="any"
                                                    max={slQtyPercentage ? 100 : "any"}
                                                    min={slQtyPercentage ? 0 : "any"}
                                                    placeholder={convertQtyToString(props.tpsl?.stopLoss?.Qty, slQtyPercentage) ?? "qty"}
                                                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        if (slQtyPercentage) {
                                                            values.slQty = new Qty(undefined, parseFloat(e.target.value))
                                                        } else {
                                                            values.slQty = new Qty(parseFloat(e.target.value))
                                                        }
                                                    }}
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    {slQtyPercentage ? "%" : symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? ""}
                                                </div>
                                            </div>

                                            <Switch
                                                checked={slQtyPercentage}
                                                onChange={setSlQtyPercentage}
                                                className="group mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
                                            >
                                                <span
                                                    aria-hidden="true"
                                                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                />
                                            </Switch>
                                            <label className="ml-3 mt-2 text-sm">
                                                <span className="font-medium text-gray-900">Set Quantity as a Percentage</span>
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="submit"
                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => props.setOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>

                    </div>
                </div>
            </form>
        </Dialog>
    )
}